import React, { useContext, useEffect, useState } from 'react'
import { brokerProjectListing, deleteBrokerSV, getAllBrokersSV, getClosingManagerByProjectId, getProjectByUserAccess, insertLead, leadCheckforBroker } from '../../services/private/borker.service';
import moment from 'moment';
import Tabs from '../../components/HOC/Tabs';
import SiteVisitLeads from './SiteVisitLeads';
import SiteVisitCompletedLeads from './SiteVisitCompletedLeads';
import SitiVisitAttempts from './SitiVisitAttempts';
import LeadsFooterBar from '../LeadsManager/LeadsFooterBar';
import SiteVisitCancelled from './SiteVisitCancelled';
import CreateLeadModal from './Modal/CreateLeadModal';
import SearchBox from '../../components/SearchBox';
import LeadExistModal from './Modal/LeadExistModal';
import BrokerLeadsFilter from './Modal/BrokerLeadsFilter';
import useDocumentTitle from '../../custom hooks/useDocumentTitile';
import { getColumnPreferenceForSvTracker, getMasterColumnPreferenceForSvTracker, getUsersForSettings, saveColumnPreferenceForSvTracker } from '../../services/private/company.service';
import { toast } from 'react-toastify';
import Consumer from '../../helpers/context';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import ConfirmDeletionCustom from '../../components/Modals/ConfirmDeletionCustom';
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import "yet-another-react-lightbox/plugins/captions.css";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import { SV_ENUMS, enums } from '../../helpers/filtersEnum';
import InternalTabs from '../../components/HOC/InternalTabs';
import FiltersTag from './FiltersTag';
import InputSelect from '../../components/InputGroup/InputSelect';

const tabList = [
    {
        name: 'Scheduled',
        value: 'scheduled'
    },
    {
        name: 'Completed',
        value: 'completed'
    },
    // {
    //     name: 'Attempts',
    //     value: 'attempt'
    // },
    {
        name: 'Cancelled',
        value: 'cancelled'
    }
]

const svTabList = [
    {
        name: 'All',
        value: 'completed'
    },
    {
        name: 'Un-Tagged',
        value: 'not_tagged'
    },
    {
        name: 'Tagged',
        value: 'tagged'
    },

]

const BorkerLeads = () => {
    const [events, setEvents] = useState([]);
    const [selectedTab, setSelectedTab] = useState({
        name: 'Scheduled',
        value: 'scheduled'
    })
    const [tabRowCount, setTabRowCount] = useState({});
    const [showingResults, setShowingResults] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [leadsCount, setLeadsCount] = useState();
    const [searchText, setSearchText] = useState('');
    const [showCreateLeadModal, setShowCreateLeadModal] = useState(false)
    const [selectedLead, setSelectedLead] = useState('');
    const [showLeadExistModal, setShowLeadExistModal] = useState(false);
    const [leadExistResponse, setLeadExistResponse] = useState({});
    const [refreshTime, setRefreshTime] = useState('');
    const [filters, setFilters] = useState({});
    const [showFilter, setShowFilter] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [intentStatuses, setIntentStatuses] = useState([]);
    const [showLightModal, setShowLightModal] = useState(false);
    const [selectedImg, setSelectedImg] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [internalTabRowCount, setInternalTabRowCount] = useState({});
    const [scheduleColumn, setScheduleColumn] = useState([]);
    const [completedColumn, setCompletedColumn] = useState([]);
    const [cancelledColumn, setCancelledColumn] = useState([]);
    const [dashboardColumns, setDashboardColumns] = useState([]);
    const [showCustomisedColumnModal, setShowCustomisedColumnModal] = useState(false)
    const [assignDisable, setAssignDisable] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState({});
    const [selectedInternalTab, setSelectedInternalTab] = useState({
        name: 'All',
        value: 'completed'
    },);

    useDocumentTitle('Site Visit Tracker');
    let { companyConfig } = useContext(Consumer);

    const getAllUsers = () => {
        getClosingManagerByProjectId(selectedProject?.uuid).then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name,
                        // label: data.name,
                        value: data.id
                    }
                })
                setAllUsers(userData)
            }
        })
    }

    const getAllProjects = async () => {
        await getProjectByUserAccess().then((res) => {
            if (res.status === 200) {
                const mapped = res.data.data.map(item => ({ ...item, label: item.name, value: item.id }))
                setProjects(mapped);
                if (localStorage.getItem('svProject')) {
                    const projectId = JSON.parse(localStorage.getItem('svProject'))
                    const selectedProj = mapped.find(i => i.value === projectId)
                    if (selectedProj) {
                        setSelectedProject(selectedProj)
                    } else {
                        setSelectedProject(mapped[0])
                        localStorage.setItem('svProject', JSON.stringify(mapped[0].value))
                    }
                } else {
                    setSelectedProject(mapped[0]);
                    localStorage.setItem('svProject', JSON.stringify(mapped[0].value))
                }
            }
        }).catch((err) => {
            console.log("Something went wrong", err);
        })
    }

    const getSelectedTab = () => {
        if (selectedTab?.value === 'scheduled' || selectedTab?.value === 'cancelled') {
            return selectedTab?.value
        } else {
            return selectedInternalTab?.value
        }
    }

    const getEvents = async () => {
        setIsLoading(true)
        const payload = {
            "pageNumber": currentPage,
            "perPage": showingResults,
            "searchText": searchText,
            "tab": getSelectedTab(),
            filters: filters
        }
        try {
            const res = await getAllBrokersSV(selectedProject?.uuid || '', payload);
            if (res.data.status === 200) {
                setIsLoading(false)
                setRefreshTime(Date.now())
                setEvents(res.data.data?.entries);
                const totalPages = Math.ceil(
                    Number(res.data.data?.stats[selectedTab.value]) / showingResults
                );
                setMaxPage(totalPages);
                setLeadsCount(res.data.data?.stats[selectedTab.value]);
                let stats = {}
                tabList.forEach(i => {
                    stats = { ...stats, [i.name]: `${res.data.data?.stats[i.value]}` }
                })
                let internalStat = {}
                svTabList.forEach(i => {
                    internalStat = { ...internalStat, [i.name]: `${res.data.data?.stats[i.value]}` }
                })
                setInternalTabRowCount(internalStat)
                setTabRowCount(stats)
            }
        } catch (err) {
            if (err.response.data.message === 'No site visits found' || err.response.data.status === 404) {
                setEvents([]);
                setIsLoading(false)
                // setTabRowCount({})
            }
        }
    }


    const getColumns = async () => {
        const res = await getColumnPreferenceForSvTracker();
        if (res.data.status === 200) {
            let dashboardColumns = []
            res.data.data.forEach(items => {
                let columnsList = [];
                let selectedColumn = [];

                items.columns.forEach(column => {
                    if (column.is_selected) {
                        selectedColumn.push(column);
                    }

                    if (!column.is_selected) {
                        columnsList.push(column);
                    }
                })

                // adding column according to tab with sorting
                dashboardColumns.push({
                    tab: items.tab,
                    columns: {
                        selected: selectedColumn.sort((a, b) => a.placement - b.placement),
                        notSelected: columnsList
                    }
                })

                // data for table columns
                if (items.tab === 'Scheduled') {
                    setScheduleColumn(selectedColumn)
                }
                if (items.tab === 'Completed') {
                    setCompletedColumn(selectedColumn)
                }
                if (items.tab === 'Cancelled') {
                    setCancelledColumn(selectedColumn)
                }
            })
            setDashboardColumns(dashboardColumns)
        }
    }

    /**
    * Updating re-arranged list items
    */
    const updateColumnList = (tab, list) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        const itemColumn = columnClone[index].columns
        columnClone[index].columns = { ...itemColumn, selected: list }
        setDashboardColumns([...columnClone]);
    };

    const handleChangeSelection = (tab, selected, notSelected) => {
        let columnClone = [...dashboardColumns];
        const index = columnClone.findIndex(item => item.tab === tab)
        columnClone[index].columns = { selected, notSelected }
        setDashboardColumns([...columnClone]);
    };

    const saveCurrentUserColumnPrefrence = () => {
        const preference = dashboardColumns.map(items => {
            items.columns.selected.forEach((item, index) => {
                item.placement = index + 1;
            })
            return {
                tab: items.tab,
                columns: [...items.columns.selected, ...items.columns.notSelected]
            }
        })
        saveColumnPreferenceForSvTracker({ preference }).then(res => {
            if (res.data.status === 200) {
                toast.success(res.data.message);
                getColumns();
                setShowCustomisedColumnModal(false);
                // getLeadsData();
            }
        }).catch(err => {
            console.log({ err })
        })
    }

    const handleCheckLeadExist = async (lead, checkFrom, assignTo) => {
        try {
            setAssignDisable(true)
            const res = await leadCheckforBroker(lead.uuid)
            if (res.data.status === 200) {
                setSelectedLead(lead)
                setLeadExistResponse(res.data.data);
                setShowLeadExistModal(true);
                setAssignDisable(false)
            }
        } catch (err) {
            if (err.response.data.status === 404) {
                setSelectedLead(lead)
                if (checkFrom === 'table') {
                    handleUpdateAssignedTO(lead.uuid, assignTo)
                } else {
                    setShowCreateLeadModal(true)
                    setAssignDisable(false)
                }
            }
        }
    }

    const handleDeleteSV = async () => {
        const res = await deleteBrokerSV(selectedLead.uuid);
        if (res.data.status) {
            toast.success(res.data.message)
            setShowDeleteModal(false);
            getEvents();
        }
    }

    const handleUpdateAssignedTO = async (uuid, assignTo) => {
        const payload = {
            assigend_to: assignTo,
            followupScheduleDate: moment().add('days', 1),
            intentId: intentStatuses.find(i => i.label === 'Hot Lead').value,
            tagging_validity: 30,
            tagging_validity_unit: 'days'
        }
        try {
            await insertLead(uuid, payload).then(res => {
                if (res.status === 200) {
                    toast.success(res.data.message);
                    getEvents()
                    setAssignDisable(false);
                }
            })
        } catch (err) {
            toast.error(err.response.data.message);
            setAssignDisable(false)
        }
    }

    useEffect(() => {
        if (Object.keys(companyConfig).length > 0) {
            setIntentStatuses(companyConfig.Intent);

        }
    }, [companyConfig]);

    useEffect(() => {
        getAllProjects();
        getColumns();

    }, []);

    // const handleSelectProject = () => {
    //     const projectId = JSON.parse(localStorage.getItem('svProject'))
    //     const selectedProj = projects.find(i => i.value === projectId)
    //     setSelectedProject(selectedProj)
    // }
    // useEffect(() => {
    //     if (localStorage.getItem('svProject') && projects.length > 0) {
    //         handleSelectProject();
    //     }
    // }, [projects]);
    console.log(selectedProject, ';selectedProject')
    useEffect(() => {
        if (selectedProject && Object.keys(selectedProject).length > 0) {
            getEvents();
        }
    }, [selectedTab, showingResults, currentPage, searchText, filters, selectedInternalTab, selectedProject]);

    useEffect(() => {
        if (selectedProject && Object.keys(selectedProject).length > 0) {
            getAllUsers();
        }
    }, [selectedProject])

    const removeFilterValue = (keyValue) => {
        let oldFilters = structuredClone(filters);
        delete oldFilters[keyValue]
        setFilters({ ...oldFilters });
    }

    return (
        <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0">
            <div className="!px-5 !border-b !border-grayLight">
                <div className="row d-flex d-flex flex-md-fill flex-wrap !mb-4">
                    <div className="col-md-8 col-6 d-flex flex-md-fill flex-col xl:items-center  xl:!flex-row">
                        <div className='flex items-center flex-col md:flex-row'>
                            <h1 className="text-2xl mb-0 black fw-po-medium !mr-4">Site Visit Tracker</h1>
                            <div className='w-[200px] !mr-4'>
                                <InputSelect
                                    value={selectedProject}
                                    onChange={(val) => {
                                        setSelectedProject(val)
                                        localStorage.setItem('svProject', JSON.stringify(val.value))
                                    }}
                                    options={projects}
                                    placeholder='Select Project'
                                    boxclass='!mr-0'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-6 d-flex justify-content-end align-items-md-end aling-items-center d-flex flex-md-fill">
                        <SearchBox searchText={searchText} setSearchText={setSearchText} placeholder={'Search for Site Visits'} classname='mr-0' />
                        <div className="filter-btn !ml-4">
                            <button className={`d-flex align-items-center ${Object.keys(filters)?.length > 0 ? " border-0 pr-hover  active" : "border-0 btn"}`} type="button" onClick={() => setShowFilter(true)}>
                                <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z" fill="#696974"></path></svg>
                                <span className="text-capitalize">Filter {Object.keys(filters)?.length > 0 ? ` (${Object.keys(filters)?.length})` : " "}</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='flex w-100 justify-between'>
                    <Tabs
                        tabList={tabList}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabName={selectedTab}
                        tabRowCount={tabRowCount}
                    />
                    <p className="mb-0 font-italic black !ml-4">
                        Last Updated: {moment(refreshTime).format('h :mm a, Do MMM YY')}
                        <span className="text-underline cursor-pointer pr-hover ml-1" onClick={() => {
                            getEvents();
                        }}>
                            refresh
                        </span>
                    </p>
                </div>
            </div>
            <div className="flex items-center">

                {selectedTab.name === 'Completed' &&
                    <div className="!pl-7 !pr-4 !pt-3">
                        <InternalTabs
                            tabList={svTabList}
                            selectedTab={selectedInternalTab}
                            setSelectedTab={setSelectedInternalTab}
                            tabName={selectedInternalTab}
                            tabRowCount={internalTabRowCount}
                        />
                    </div>
                }
                {selectedTab.name === 'Completed' && Object.keys(filters).length > 0 && <div className="border-r-2 !h-8"></div>}
                {
                    Object.keys(filters).length > 0 ? <FiltersTag filters={filters} removeFilterValue={removeFilterValue} /> : " "
                }
            </div>
            <div className='broker-page'>
                {selectedTab?.name === 'Scheduled' &&
                    <SiteVisitLeads
                        leads={events}
                        getEvents={getEvents}
                        searchText={searchText}
                        setShowLightModal={setShowLightModal}
                        setSelectedImg={setSelectedImg}
                        setShowDeleteModal={setShowDeleteModal}
                        setSelectedLead={setSelectedLead}
                        isLoading={isLoading}
                        selectedLead={selectedLead}
                        scheduleColumn={scheduleColumn}
                        updateColumnList={updateColumnList}
                        handleChangeSelection={handleChangeSelection}
                        saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                        dashboardColumns={dashboardColumns}
                        setShowCustomisedColumnModal={setShowCustomisedColumnModal}
                        showCustomisedColumnModal={showCustomisedColumnModal}
                    />}
                {selectedTab?.name === 'Completed' && <SiteVisitCompletedLeads
                    leads={events}
                    setSelectedLead={setSelectedLead}
                    setShowCreateLeadModal={setShowCreateLeadModal}
                    getEvents={getEvents}
                    handleCheckLeadExist={handleCheckLeadExist}
                    searchText={searchText}
                    allUsers={allUsers}
                    setEvents={setEvents}
                    handleUpdateAssignedTO={handleUpdateAssignedTO}
                    setShowLightModal={setShowLightModal}
                    setSelectedImg={setSelectedImg}
                    setShowDeleteModal={setShowDeleteModal}
                    isLoading={isLoading}
                    tabRowCount={internalTabRowCount}
                    selectedTab={selectedInternalTab}
                    setSelectedTab={setSelectedInternalTab}
                    setTabRowCount={setInternalTabRowCount}
                    tabList={svTabList}
                    completedColumn={completedColumn}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                    saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                    dashboardColumns={dashboardColumns}
                    setShowCustomisedColumnModal={setShowCustomisedColumnModal}
                    showCustomisedColumnModal={showCustomisedColumnModal}
                    assignDisable={assignDisable}
                />}
                {/* {selectedTab?.name === 'Attempts' && <SitiVisitAttempts leads={events} />} */}
                {selectedTab?.name === 'Cancelled' && <SiteVisitCancelled
                    leads={events}
                    searchText={searchText}
                    setShowLightModal={setShowLightModal}
                    setSelectedImg={setSelectedImg}
                    setSelectedLead={setSelectedLead}
                    setShowDeleteModal={setShowDeleteModal}
                    isLoading={isLoading}
                    cancelledColumn={cancelledColumn}
                    updateColumnList={updateColumnList}
                    handleChangeSelection={handleChangeSelection}
                    saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                    dashboardColumns={dashboardColumns}
                    setShowCustomisedColumnModal={setShowCustomisedColumnModal}
                    showCustomisedColumnModal={showCustomisedColumnModal}
                />}
                <LeadsFooterBar
                    showingResults={showingResults}
                    setShowingResults={setShowingResults}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    maxPage={maxPage}
                    leadsCount={leadsCount}
                    classname='!basis-0 !z-30'
                />
            </div>

            {showCreateLeadModal && <CreateLeadModal
                show={showCreateLeadModal}
                setShow={setShowCreateLeadModal}
                selectedLead={selectedLead}
                getEvents={getEvents}
                allUsers={allUsers}
            />}
            {
                showLeadExistModal && <LeadExistModal
                    show={showLeadExistModal}
                    closeModal={() => setShowLeadExistModal(false)}
                    selectedLead={selectedLead}
                    leadExistResponse={leadExistResponse}
                />
            }
            {
                showFilter && <BrokerLeadsFilter
                    show={showFilter}
                    setShow={setShowFilter}
                    filtersData={filters}
                    setFiltersData={setFilters}
                    projects={projects}
                />
            }
            <div className='broker-image'>
                {showLightModal && <Lightbox
                    styles={{ navigationNext: { "yarl__navigation_next": "display: none !important" } }}
                    className={'broker-lightbox'}
                    open={showLightModal}
                    close={() => {
                        setShowLightModal(false)
                        setSelectedImg('')
                    }}
                    slides={[{ src: selectedImg.image, title: `${selectedImg.customer_name} - ${selectedImg.customer_mobile}` }]}
                    plugins={[Captions]}
                // video={{
                //     controls: true,
                //     playsInline: true,
                //     autoPlay: false,
                //     loop: false,
                //     muted: true,
                //     disablePictureInPicture: false,
                //     disableRemotePlayback: false,
                //     controlsList: ["nodownload" | "nofullscreen" | "noremoteplayback"].join(" "),
                //     crossOrigin: '',
                //     preload: '',
                // }}
                />}
            </div>
            {
                showDeleteModal && selectedLead.uuid && <ConfirmDeletionCustom
                    show={showDeleteModal}
                    setShow={setShowDeleteModal}
                    deleteHandler={handleDeleteSV}
                    deleteText={selectedLead.customer_name}
                />
            }
        </div>

    )
}

export default BorkerLeads